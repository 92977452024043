import React from "react"
import Grid from "../components/Grid/Grid"
import Products from "../components/Products/Products"
import PageIntro from "../components/PageIntro/PageIntro"
import SEO from "../components/SEO"

const products = () => {
  return (
    <>
      <SEO title="Products" />
      <section className="section-padding">
        <Grid>
          <PageIntro
            title="Endless outdoor solutions"
            subTitle="Outdoor advertising that reaches Goans where they eat, drink, shop and live."
            paragraph="Our outdoor advertising is big, bold and dynamic, offering contstant repetitive exposure, 24 hours a day, 7 days a week"
          />
          <Products />
        </Grid>
      </section>
    </>
  )
}

export default products
